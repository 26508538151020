import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Title from "../components/title"; 

const Spoluprace = ({data}) => (
    <>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Spolupráce | Spark Performance</title> 
        </Helmet>
      <Layout>
   
      <Title title={'Spolupráce'}  />
 
     
<main className="mt-5  pb-8">
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="sr-only">O mně</h1>
      
      <div className="grid grid-cols-1 gap-4 items-start  lg:gap-8">
       
        <div className="grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-none">
          <section aria-labelledby="section-1-title">
            <h2 className="sr-only" id="section-1-title">Jsem lektorem a překladatelem NCSC certifikace Elite Performance Institut</h2>
            <div className="rounded-lg bg-white overflow-hidden shadow   p-6">
               <div className="prose text-gray-500 max-w-none">
              
              {data?.scomos?.pageFindOneByUrl?.content && 
                <div dangerouslySetInnerHTML={{__html: data?.scomos?.pageFindOneByUrl?.content}}>
                </div>
              }
              
                        
               </div>            
            </div>
          </section>
        </div>
 
      </div>
    </div>
  </main>
    </Layout>
  </>
)
 
export default Spoluprace

export const query = graphql`
  query Content {
    scomos {
      pageFindOneByUrl(url:"spoluprace/") {
          content
      }
    }
  }
` 